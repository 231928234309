.fenixBody {
  background-image: none !important;
  background-color: #b89b61 !important;
}
.customForm {
  margin-top: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  color: #ffffff;
}
.fenixBtn {
  margin-top: 20px;
}

.free {
  background: #039917 !important;
  color: #fff !important;
}
.occupied {
  background: #f20c0c !important;
  color: #fff !important;
}
