/* .container {
  font-family: "Roboto Slab", serif;
  color: var(--colorRestTemplate2);
  font-size: 25px;
  font-weight: 300;
} */
:root {
  --colorRestTemplate1: #ddd9d3;
  --colorRestTemplate2: #cabaab;
  --colorRestTemplate3: #886e55;
  --colorRestTemplate4: #5e4935;
  --colorRestTemplate5: #39291a;
}

.bottom_marg {
  margin-bottom: 20px;
}
.bottom_marg_small {
  margin-bottom: 10px;
}
.left_pad {
  margin-left: 30px;
}

.large_container {
  position: absolute;
  box-sizing: border-box;
  background: var(--colorRestTemplate4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  gap: 30px;
  padding: 30px;
  font-family: "Roboto Slab", serif;
  color: var(--colorRestTemplate2);
  font-size: 25px;
  font-weight: 300;
}

.third {
  /*border:1px solid red;*/
  flex: 1;
}

.headline {
  /*border:1px solid blue;*/
  border-bottom-left-radius: 300px;
  border-top-left-radius: 300px;
  background: rgb(57, 41, 26);
  background: linear-gradient(90deg, rgba(57, 41, 26, 1) 0%, rgba(57, 41, 26, 0) 100%);
  display: flex;
  align-items: center;
}

.headline div {
  /*border:1px solid green;*/
}

.headline_icon {
  padding: 5px;
  padding-right: 10px;
}

.headline_icon img {
  width: 50px;
}

.headline_text {
  color: var(--colorRestTemplate2);
  font-size: 30px;
  font-weight: 700;
  flex: 1;
}

.headline_text img {
  display: inline-block;
  width: 30px;
}

.headline_price {
  color: var(--colorRestTemplate1);
  font-weight: 400;
}

.info_box {
  background: #715942;
  padding: 10px;
}

.small_text {
  font-size: 40%;
}

.icecream_pricing {
  font-size: 80%;
  /* color: #ffffff; */
  font-weight: bold;
}

.icecream_pricing img {
  display: inline-block;
  width: 30px;
}

.ice_price_row {
  display: flex;
  align-items: center;
  padding: 3px 0px;
  gap: 5px;
}

span.ice_price {
  font-weight: 500;
  color: #ffffff;
  font-weight: bold;
}

.gelato_wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
}

.gelato_wrapper span {
  font-size: 60%;
}

.lemonades_category {
  padding-bottom: 10px;
}

.lemonades_category .name {
  font-weight: 700;
}

.lemonades_category .list span:after {
  content: ",";
}

.lemonades_category .list span:last-child:after {
  content: "";
}
.lemonades_category p {
  padding: 0;
  margin: 4px 0;
}
.drinks_wrapper .item {
  display: flex;
  padding-bottom: 5px;
}

.drinks_wrapper .item .name {
  flex: 1;
}

.drinks_wrapper .item .price {
  color: var(--colorRestTemplate1);
  font-weight: 400;
}

.third.boxed {
  display: flex;
  flex-direction: column;
}

.box {
}

.big_logo {
  flex-grow: 1;
  background: url("https://login.datavision-online.eu/uploads/files/33/Images/267b62306e04397a3aa5e425cddf8b57.png")
    center center no-repeat;
  background-size: contain;
  width: 55%;
  margin-left: 18%;
}

.big_logo img {
  width: 10%;
}

.coffee_box {
  display: flex;
}

.coffee_icon {
}

.coffee_icon img {
  max-width: 50px;
}

.coffee_text {
  flex: 1;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coffee_row {
  display: block;
  width: 100%;
}

.coffee_fix {
  display: flex;
  padding-left: 10px;
}

.coffee_name,
.coffee_sub_name {
  flex: 1;
}

.coffee_price,
.coffee_sub_price {
  color: var(--colorRestTemplate1);
  font-weight: 400;
}

.coffee_sub_name,
.coffee_sub_price {
  font-size: 70%;
}

.coffee_sub_name {
  font-weight: 700;
}

.meniny span {
  color: var(--colorRestTemplate1);
  font-weight: 600;
}

.headline_price:after,
.price:after,
.coffee_price:after,
.coffee_sub_price:after,
span.ice_price:after {
  content: "€";
}
.headline_price:empty:after,
.price:empty:after,
.coffee_price:empty:after,
.coffee_sub_price:empty:after,
span.ice_price:empty:after {
  content: "";
}

.clear {
  clear: both;
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
.left {
  text-align: left;
}
.fleft {
  float: left;
}
.fright {
  float: right;
}

/*----------------------------------------------------------------------------------------------- RESPONSIVE ------------------------------------*/

@media screen and (max-width: 800px) {
}
