.landscape .containerRestaurantMain {
  width: 1300px;
  height: 850px;
}
.outer-container {
  display: flex;
  flex-direction: row;
}
.outer-container2 {
  display: flex;
  flex-direction: row;
}
.container2 {
  width: 620px;
}
.container2_1 {
  width: 80%;
}
.container2_2 {
  width: 20%;
}
.landscape .foodTitle {
  width: 1300px;
  opacity: 0.7;
  background-color: #000000;
  color: #ffffff;
  font-size: 50px !important;
  position: absolute;
  z-index: 9999;
  bottom: 50px;
  padding: 10px;
  text-align: center;
}
.allergens {
  width: 1300px;
  color: #ffffff;
  font-size: 20px;
  position: absolute;
  z-index: 9999;
  bottom: 0px;
  padding: 10px;
  text-align: center;
}
.soupTitle {
  width: 100%;
  opacity: 0.7;
  background-color: #000000;
  color: #ffffff;
  font-size: 30px;
  position: absolute;
  text-align: center;
  z-index: 9999;
  top: 0px;
  padding: 10px;
}
.foodText {
  padding: 10px 5px;
  font-size: 18px;
}
.portrait .foodTitle {
  width: 100%;
  opacity: 0.7;
  background-color: #000000;
  color: #ffffff;
  font-size: 50px !important;
  position: absolute;
  z-index: 9999;
  bottom: 50px;
  padding: 10px;
  text-align: center;
}
.mealLabel {
  width: 510px;
  padding: 0 5px;
}
.mealPrice {
  text-align: right;
  width: 97px;
}
.foodTitleList {
  font-size: 30px;
  padding: 5px;
  text-align: center;
  line-height: 15px;
}

table {
  border-collapse: collapse;
}

/* PORTRAIT  */
.portrait {
  width: 1080px;
  height: 1642px;
}

.portrait img {
  width: 100%;
}
.portrait .container2 {
  width: 1080px;
  height: 850px;
}

.portrait .foodTitleList {
  font-size: 60px;
  padding: 40px;
  text-align: center;
  line-height: 30px;
}

.portrait .mealLabel {
  width: 890px;
  padding: 0 20px;
}
.portrait .mealPrice {
  text-align: right;
  width: 148px;
}
.portrait .foodText {
  padding: 10px 5px;
  font-size: 36px;
}
.portrait .allergens {
  width: 100%;
}
